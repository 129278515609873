import React from "react";
import { Button, Card, Form, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

import Alert from "../../Notifications/Alert";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import Errors from "../../Notifications/Errors";

const LoginForm = ({ onChange, onSubmit }) => {
  const [inputType, setInputType] = React.useState("password");
  const [showPassword, setShowPassword] = React.useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
    setInputType(inputType === "password" ? "text" : "password");
  };

  return (
    <Form onSubmit={(e) => onSubmit(e)}>
      <Card.Title className="text-center text-muted mt-3 mb-3">
        Log In
      </Card.Title>

      <Alert />

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter email"
          minLength="8"
          name="email"
          onChange={(e) => onChange(e)}
          required
        />

        <Errors key="email" current_key="email" />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Link
          to="/forgot-password"
          className="text-muted ms-1 text-decoration float-end"
        >
          Forgot your password?
        </Link>
        <Form.Label>Password</Form.Label>
        <InputGroup>
          <Form.Control
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="Password"
            onChange={(e) => onChange(e)}
            required
          />
          <InputGroup.Text onClick={toggleShowPassword}>
            {inputType === "password" ? <FaRegEyeSlash /> : <FaRegEye />}
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>
      {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Remember me"
                    onChange={(e) => onChangeCheck(e)}
                    required
                  />
                  </Form.Group> */}
      <Form.Group className="float-end">
        <Button variant="primary" type="submit">
          Log In
        </Button>
      </Form.Group>
    </Form>
  );
};

export default LoginForm;
