import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import Spinner from "../Spinner";

import { login, loadPage } from "../../actions/auth";

import LoginForm from "./LoginForm";
import MultiFactorForm from "./MultiFactorForm";

const LoginPage = ({ login, loadPage, loading, otpSent }) => {
  const initialState = {
    email: "",
    password: "",
    rememberMe: "",
    onetime_password: "",
  };

  const navigate = useNavigate();
  const [formData, setFormData] = React.useState(initialState);
  const [screen, setScreen] = React.useState("");
  const [enableResendOTP, setEnableResendOTP] = React.useState(false);
  const [showResendButton, setShowResendButton] = React.useState(false);
  const [textToShowForResendTimer, setTextToShowForResendTimer] =
    React.useState("");

  const showResendOTPOptionWithTimer = () => {
    var timeLeft = 60;
    var downloadTimer = setInterval(function () {
      if (timeLeft <= 0) {
        setShowResendButton(true);
        setTextToShowForResendTimer("");
        clearInterval(downloadTimer);
      } else {
        setTextToShowForResendTimer(
          "Resend One Time Password in " + timeLeft + " seconds"
        );
      }
      timeLeft -= 1;
    }, 1000);
  };

  React.useEffect(() => {
    if (otpSent === true) {
      setScreen("multifactor");
    }

    if (otpSent === false) {
      setScreen("login");
    }
  }, [otpSent]);

  const onChange = (e) => {
    if (!e) return;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChangeCheck = (e) => {
    if (!e.target) return;

    setFormData({
      ...formData,
      terms: e.target.checked ? true : false,
    });
  };

  const onSubmit = (e, action) => {
    e.preventDefault();

    setShowResendButton(false);

    const submitData = {
      email: formData.email,
      password: formData.password,
      askForTwoFactor: true,
    };

    if (action === "resend-OTP") {
      submitData["requestResendOTP"] = true;
    }

    if (action === "submit-multifactor") {
      submitData["askForTwoFactor"] = false;
      submitData["onetime_password"] = formData.onetime_password;
    }

    login(submitData, navigate).then((res) => {
      if (
        res.status !== false &&
        res.response &&
        res.response.askForTwoFactor === true
      ) {
        console.log("Multifactor asked.", "Resend OTP option enabled.");
        setEnableResendOTP(true);
        showResendOTPOptionWithTimer();
      }
    });
  };

  React.useEffect(() => {
    loadPage();
  }, []);

  return loading ? (
    <Spinner />
  ) : (
    <React.Fragment>
      <Helmet>
        <title>Login | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <Container className="account-pages circle-1">
        <Row className="ac-page justify-content-center">
          <Col lg="10">
            <Card>
              <Card.Header className="pt-3 pb-3 text-center bg-primary">
                <Link to="/">
                  <span>
                    <img src={logo} alt="" height="26" />
                  </span>
                </Link>
              </Card.Header>
              <Card.Body className="p-4">
                {screen === "login" ? (
                  <LoginForm onChange={onChange} onSubmit={onSubmit} />
                ) : null}

                {screen === "multifactor" ? (
                  <MultiFactorForm
                    onChange={onChange}
                    onSubmit={onSubmit}
                    enableResendOTP={enableResendOTP}
                    showResendButton={showResendButton}
                    textToShowForResendTimer={textToShowForResendTimer}
                  />
                ) : null}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

LoginPage.proTypes = {
  errorList: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errorList: state.errors,
  loading: state.auth.loading,
  isAuthenticated: state.auth.isAuthenticated,
  otpSent: state.auth.otpSent,
  otpResendOption: state.auth.otpResendOption,
});

export default connect(mapStateToProps, {
  login,
  loadPage,
})(LoginPage);
