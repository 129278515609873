import React from "react";
import { Button, Card, Form, Row, Col } from "react-bootstrap";

import Alert from "../../Notifications/Alert";
import Errors from "../../Notifications/Errors";

const MultiFactorForm = ({
  onChange,
  onSubmit,
  enableResendOTP,
  showResendButton,
  textToShowForResendTimer,
}) => {
  return (
    <Form
      onSubmit={(e) => onSubmit(e, "submit-multifactor")}
      autoComplete="off"
    >
      <Card.Title className="text-center text-muted mt-3 mb-3">
        Multi Factor Authentication
      </Card.Title>
      <Card.Text className="text-center text-muted mt-3 mb-3">
        Enter One Time Password sent to your registered mobile number.
      </Card.Text>
      <Alert />

      <Form.Group className="mb-3" controlId="onetime_password">
        <Form.Label>One Time Password</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter One Time Password"
          minLength="6"
          maxLength="8"
          name="onetime_password"
          onChange={(e) => onChange(e)}
          autoComplete="off"
          required
        />

        <Errors key="onetime_password" current_key="onetime_password" />
      </Form.Group>

      <Row>
        <Col md="8">
          {enableResendOTP && (
            <>
              {showResendButton === true ? (
                <Button
                  variant="link"
                  className="px-0 mx-0"
                  onClick={(e) => onSubmit(e, "resend-OTP")}
                >
                  Resend OTP
                </Button>
              ) : (
                <span color="link" className="px-0 mx-0 btn">
                  {textToShowForResendTimer}
                </span>
              )}
            </>
          )}
        </Col>

        <Col md="4">
          <Form.Group className="float-end">
            <Button variant="primary" type="submit">
              Verify & Login
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default MultiFactorForm;
