import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import { FaRegCopyright } from "react-icons/fa";

const DefaultFooter = () => {
  const today = new Date();

  return (
    <footer className="footer-section">
      <div className="container-fluid">
        <Row>
          <Col className="col-lg-10">
            <span>
              {today.getFullYear()} <FaRegCopyright /> VericyDx
            </span>
          </Col>
          <Col className="col-lg-2 footer-nav">
            {/* <Link to="/about">About</Link>
            <Link to="/support">Support</Link>
            <Link to="/contact-us">Contact Us</Link> */}
            <div className="pull-right">
              Powered by{" "}
              <a target="blank" href="https://innovitx.com/">
                Innovitx Inc.
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default DefaultFooter;
