import React from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { BiPlusMedical } from "react-icons/bi";

import Errors from "Notifications/Errors";
import { isEditor } from "utils/helper";

const ProcedureDataTab = ({
  procedureData,
  setProcedureData,
  onSubmitProcedureData,
  loadingOnSubmit,
  isDisabled,
  toggleEdit,
  onClickCancel,
  loggedInUser,
  selectedClaimID,
  handleTabSelect,
  CPTCodesListAll,
}) => {
  const onChange = (e, index) => {
    if (!e.target) return;
    const cpProdcedureData = procedureData.map((data, i) => {
      const data2 = { ...data };
      if (i === index) {
        data2[e.target.name] = e.target.value;
      }

      return data2;
    });

    setProcedureData(cpProdcedureData);
  };

  const handleSelect2 = (field, index) => (selectedOption) => {
    const cpProdcedureData = procedureData.map((data, i) => {
      const data2 = { ...data };
      if (i === index) {
        data2[field] = selectedOption.value;

        const filtered = CPTCodesListAll.find(
          (cpt) => cpt.cpt_code === selectedOption.value
        );

        data2["quantity"] = 1;
        data2["amount"] = filtered.fee;
        data2["testDescription"] = filtered.description;
      }

      return data2;
    });

    setProcedureData(cpProdcedureData);
  };

  const onClickHadle = () => {
    setProcedureData([
      ...procedureData,
      {
        cptCode: "",
        modifier: "",
        quantity: "",
        amount: "",
        testDescription: "",
        icd10Code: "",
      },
    ]);
  };
  const onClickNext = (e) => {
    e.preventDefault();

    // let validationRules = [
    //   {
    //     param: "orderNumber",
    //     msg: "Order number is required.",
    //   },
    // ];

    // const errors = validateForm(encounterData, validationRules);

    // if (errors.length) {
    //   setErrors(errors);
    //   return;
    // }

    handleTabSelect("insuranceData");
  };

  const onClickBack = (e) => {
    e.preventDefault();

    handleTabSelect("encounterData");
  };

  const onClickRemove = (index) => {
    setProcedureData((previousArr) =>
      previousArr.filter((each, i) => i !== index)
    );
  };

  const [CPTCodesDropdownList, setCPTCodesDropdownList] = React.useState([]);
  React.useEffect(() => {
    const list = CPTCodesListAll.map((cpt) => ({
      label: cpt.cpt_code,
      value: cpt.cpt_code,
    }));
    setCPTCodesDropdownList(list);
  }, [CPTCodesListAll]);

  return (
    <Form onSubmit={(e) => onSubmitProcedureData(e)}>
      <Row>
        {procedureData.map((each, index) => (
          <React.Fragment key={index}>
            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>CPT Code</Form.Label>
                <Select
                  id="cptCode"
                  name="cptCode"
                  options={CPTCodesDropdownList}
                  value={CPTCodesDropdownList.find(
                    (b) => b.value === each.cptCode
                  )}
                  onChange={handleSelect2("cptCode", index)}
                  isDisabled={isDisabled}
                />

                <Errors
                  current_key={`items[${index}].cptCode`}
                  key={`items[${index}].cptCode`}
                />
              </Form.Group>
            </Col>

            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>Modifier</Form.Label>
                <Form.Control
                  type="text"
                  name="modifier"
                  value={each.modifier}
                  onChange={(e) => onChange(e, index)}
                  disabled={isDisabled}
                />

                <Errors
                  current_key={`items[${index}].modifier`}
                  key={`items[${index}].modifier`}
                />
              </Form.Group>
            </Col>

            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>Quantity</Form.Label>
                <Form.Control
                  type="text"
                  name="quantity"
                  value={each.quantity}
                  onChange={(e) => onChange(e, index)}
                  disabled={isDisabled}
                />

                <Errors
                  current_key={`items[${index}].quantity`}
                  key={`items[${index}].quantity`}
                />
              </Form.Group>
            </Col>

            <Col xxl="1" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="text"
                  name="amount"
                  value={each.amount}
                  onChange={(e) => onChange(e, index)}
                  disabled={isDisabled}
                />

                <Errors
                  current_key={`items[${index}].amount`}
                  key={`items[${index}].amount`}
                />
              </Form.Group>
            </Col>

            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>Test Description</Form.Label>
                <Form.Control
                  type="text"
                  name="testDescription"
                  value={each.testDescription}
                  onChange={(e) => onChange(e, index)}
                  disabled={isDisabled}
                />

                <Errors
                  current_key={`items[${index}].testDescription`}
                  key={`items[${index}].testDescription`}
                />
              </Form.Group>
            </Col>

            <Col xxl="2" lg="3" md="4" xs="6">
              <Form.Group className="form-group">
                <Form.Label>ICD10 Code</Form.Label>
                <Form.Control
                  type="text"
                  name="icd10Code"
                  value={each.icd10Code}
                  onChange={(e) => onChange(e, index)}
                  disabled={isDisabled}
                />
                <div>
                  <small>
                    <i>Add multiple by comma (,) separated</i>
                  </small>
                </div>

                <Errors
                  current_key={`items[${index}].icd10Code`}
                  key={`items[${index}].icd10Code`}
                />
              </Form.Group>
            </Col>

            {!isDisabled ? (
              <Col xxl="1" lg="2" md="3" xs="6">
                <Button
                  className="c-red mt-4"
                  variant="link"
                  onClick={(e) => onClickRemove(index)}
                >
                  Remove
                </Button>
              </Col>
            ) : null}

            <hr className="tab-hr-line" />
          </React.Fragment>
        ))}

        {!isDisabled ? (
          <div>
            <Button color="primary" size="sm" onClick={(e) => onClickHadle()}>
              <BiPlusMedical /> Add New
            </Button>

            <div>
              <Errors current_key="items" key="items" />
            </div>
          </div>
        ) : null}
      </Row>

      {isEditor(loggedInUser) ? (
        <div className="float-end">
          {selectedClaimID ? (
            !isDisabled ? (
              <>
                <Button
                  className="m-2"
                  type="submit"
                  variant="primary"
                  disabled={loadingOnSubmit || isDisabled}
                >
                  {loadingOnSubmit ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {` Loading... `}
                    </>
                  ) : (
                    <>Save</>
                  )}
                </Button>
                <Button
                  className="ml-2"
                  type="reset"
                  variant="danger"
                  onClick={onClickCancel}
                  disabled={loadingOnSubmit || isDisabled}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                className="btn btn-outline-dark"
                size="sm"
                onClick={toggleEdit}
              >
                Click to Edit
              </Button>
            )
          ) : (
            <div>
              <Button
                className="m-2"
                variant="light"
                onClick={(e) => onClickBack(e)}
              >
                Back
              </Button>

              <Button
                type="btn"
                variant="primary"
                onClick={(e) => onClickNext(e)}
              >
                Next
              </Button>
            </div>
          )}
        </div>
      ) : null}
    </Form>
  );
};

export default ProcedureDataTab;
