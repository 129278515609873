import React from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import logo from "../../../assets/images/logo.png";
import Spinner from "../../Spinner";
import Alert from "../../../Notifications/Alert";
import Errors from "../../../Notifications/Errors";

import { resetLink, loadPage } from "../../../actions/auth";

const ForgotPassword = ({ loading, resetLink, loadPage }) => {
  const initialState = {
    email: "",
  };

  React.useEffect(() => {
    loadPage();
  }, [loadPage]);

  const [formData, setFormData] = React.useState(initialState);

  const { email } = formData;

  const onChange = (e) => {
    if (!e) return;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    resetLink({ email });
  };

  return loading ? (
    <Spinner />
  ) : (
    <React.Fragment>
      <Helmet>
        <title>Forgot Password | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <Container className="account-pages circle-1">
        <Row className="ac-page justify-content-center">
          <Col lg="10">
            <Card>
              <Card.Header className="pt-4 pb-4 text-center bg-primary">
                <Link to="/">
                  <span>
                    <img src={logo} alt="" height="18" />
                  </span>
                </Link>
              </Card.Header>
              <Card.Body className="p-4">
                <Card.Title className="text-center text-muted mt-3 mb-3">
                  Forgot Password
                </Card.Title>

                <Card.Text className="text-center text-muted mt-3 mb-3">
                  Enter your email address and we'll send you an email with
                  instructions to reset your password.
                </Card.Text>
                <Alert />
                <Form onSubmit={(e) => onSubmit(e)}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      onChange={(e) => onChange(e)}
                      required
                    />

                    <Errors current_key="email" key="email" />
                  </Form.Group>

                  <Form.Group className="float-end">
                    <Button variant="primary" type="submit">
                      Reset Password
                    </Button>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
            <Row className="text-center mt-3 mb-3">
              <Col>
                <span>Back to</span>
                <Link to="/" className="text-muted ms-1 text-decoration">
                  <strong>Log In</strong>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

ForgotPassword.propTypes = {
  resetLink: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.auth.loadingPasswordReset,
});

export default connect(mapStateToProps, { resetLink, loadPage })(
  ForgotPassword
);
